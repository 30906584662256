@font-face {
  font-family: "Cotham";
  src: local("Cotham"), url(./fonts/CothamSans.otf) format("opentype");
}

/* @font-face {
  font-family: 'JetBrains';
  src: local('JetBrains'), url(./fonts/JetBrainsMono[wght].ttf), format('truetype'); */

@import url("https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;500;600;700&display=swap");

html {
  background-color: #1a1b1e;
}

p {
  margin: 0;
}

.linkOut-1 {
  width: 1.75em;
}

.site-wrapper-1 {
  max-width: 36em;
  justify-content: center;
  margin: auto;
}

.header-title-1 {
  font-weight: 700;
  color: #fff;
  letter-spacing: 0em;
  font-family: "Bebas Neue", sans-serif;
  align-content: center;
  font-size: 3em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.body-paragraph-1 {
  font-weight: 200;
  color: #fff;
  font-family: "JetBrains Mono", monospace;
  font-size: 0.9em;
  line-height: 1.5em;
  align-items: center;
  margin-bottom: 2em;
  text-align: center;
}

.body-paragraph-2 {
  font-weight: 300;
  color: #fff;
  font-family: "JetBrains Mono", monospace;
  font-size: 0.8em;
  text-transform: uppercase;
  width: 50%;
}

/* Color Styling goes here */

.explanation-highlight-1 {
  color: #fff;
  display: block;
  justify-content: center;
}

.constraint-highlight-1 {
  font-family: "JetBrains Mono", monospace;
  font-size: 1em;
  font-weight: 300;
  color: #17ff90;
  background-color: #1a1a1a;
}

/* Color styling ends here */

.explanation-container-1 {
  width: 100%;
}

.section-container-1 {
  display: flex;
  justify-content: center;
  margin: 0em;
  padding: 0em;
}

.randomize-button-1 {
  margin-top: 2em;
  background-color: #17ff90;
  width: 100%;
  bottom: 0px;
  font-family: "JetBrains Mono", monospace;
  height: 100%;
  border: 10px solid #17ff90;
}

/* brief styling below */

.brief-container-1 {
  display: block;
  justify-content: center;
  background: #1a1a1e;
  border: 1px solid #434343;
  box-sizing: border-box;
  box-shadow: 0px 8px 20px rgba(45, 45, 45, 0.5);
  border-radius: 4px;
  width: auto;
  height: auto;
  padding: 1.5em;
}

.mission-container-1 {
  display: flex;
  justify-content: space-between;

  font-family: "JetBrains Mono", monospace;
  font-size: 0.8em;
  color: white;
  line-height: 1.75em;

  background: #1a1a1e;
  border: 1px solid #434343;
  box-sizing: border-box;
  box-shadow: 0px 8px 20px rgba(45, 45, 45, 0.5);
  border-radius: 4px;
  width: auto;
  height: auto;
  padding: 1em;
}

.brief-header-1 {
  font-family: "JetBrains Mono", monospace;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  padding-bottom: 12px;
}

.brief-links-1 {
  font-family: "JetBrains Mono", monospace;
  font-size: 1em;
  font-weight: 300;
  color: #ffc90b;
  text-decoration: none;
}

.brief-link-2 {
  text-decoration: none;
}

.brief-spacer-1 {
  height: 1em;
}

/* Footer shit */

.footer-container-1 {
  display: flex;
  justify-content: left;
  font-family: "JetBrains Mono", monospace;
  font-weight: 300;
  font-size: 0.6em;
  color: #808080;
  text-transform: uppercase;
}

/* Mobile viewport shit */

@media screen and (max-width: 600px) {
  .site-wrapper-1 {
    max-width: 24em;
    justify-content: center;
    margin: auto;
  }

  .body-paragraph-1 {
    font-weight: 200;
    color: #fff;
    font-family: "JetBrains Mono", monospace;
    font-size: 0.7em;
    line-height: 1.5em;
    align-content: center;
    margin-left: 1.5em;
    margin-right: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 2em;
  }

  .header-title-1 {
    font-weight: 700;
    color: #fff;
    letter-spacing: -0.05em;
    font-family: "Bebas Neue", sans-serif;
    align-content: center;
    font-size: 2.5em;
    margin-top: 0.25em;
    margin-bottom: 0em;
    text-transform: uppercase;
  }

  .randomize-button-1 {
    background-color: #17ff90;
    font-weight: 600;
    color: #1a1a1e;
    border-radius: 2px;
    width: 100%;
    bottom: 0px;
    font-family: "JetBrains Mono", monospace;
    height: 5em;
    border: 10px solid #17ff90;
    margin-right: 1.5em;
    margin-left: 1.5em;
  }

  .brief-container-1 {
    display: block;
    justify-content: center;
    background: #1a1a1e;
    border: 1px solid #434343;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(45, 45, 45, 0.5);
    border-radius: 4px;
    width: auto;
    height: auto;
    padding: 1em;
    margin-right: 1em;
    margin-left: 1em;
  }

  .mission-container-1 {
    display: flex;
    justify-content: space-between;

    font-family: "JetBrains Mono", monospace;
    font-size: 0.6em;
    font-weight: 100;
    color: white;

    background: #1a1a1e;
    border: 1px solid #434343;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(45, 45, 45, 0.5);
    border-radius: 4px;
    width: auto;
    height: auto;
    padding: 1em;
  }

  .brief-links-1 {
    font-family: "JetBrains Mono", monospace;
    font-size: 1em;
    font-weight: 300;
    color: #ffc90b;
    text-decoration: none;
  }

  .brief-header-1 {
    font-family: "JetBrains Mono", monospace;
    font-size: 0.6em;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: white;
    text-transform: uppercase;
    padding-bottom: 12px;
  }

  .footer-container-1 {
    display: flex;
    justify-content: left;
    font-family: "JetBrains Mono", monospace;
    font-weight: 300;
    font-size: 0.6em;
    color: #5f5f5f;
    text-transform: uppercase;
  }

  .constraint-highlight-1 {
    font-family: "JetBrains Mono", monospace;
    font-size: 1em;
    font-weight: 300;
    color: #17ff90;
    background-color: #1a1a1a;
  }
}
